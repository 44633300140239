<template>
  <div class="dialog">
    <el-dialog
      :title="typeFlag == 'add' ? '添加' : '修改'"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="账号" prop="uname">
          <el-input
            size="small"
            v-model="ruleForm.uname"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input
            size="small"
            v-model="ruleForm.pwd"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="rePwd" required>
          <el-input
            size="small"
            v-model="ruleForm.rePwd"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="管理城市" prop="resp_area">
          <el-select
            size="small"
            v-model="ruleForm.resp_area"
            placeholder="请选择"
            @change="changeCate"
            multiple
            filterable
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.ShortName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单权限" prop="auth_menu_json">
          <el-tree
            :props="props"
            :data="menus"
            show-checkbox
            ref="tree"
            node-key="id"
            @check-change="handleCheckChange"
          >
          </el-tree>
          <!-- <el-checkbox-group v-model="ruleForm.auth_menu_json">
            <el-checkbox v-for="item in tags" :label="item.id" :key="item.id">{{
              item.tag_name
            }}</el-checkbox>
          </el-checkbox-group> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="close()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { allCate } from "@/api/base";
import { addInterest, tagList } from "@/api/classTag.js";
import {
  getAdminList,
  saveAdmin,
  changeAdminStatus,
  getManagerCity,
  getManagermenu,
} from "@/api/accountSet.js";
import Qiniu from "@/components/qiniu.vue";
export default {
  name: "ClassAdd",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    typeFlag: {
      // 判断是新建 编辑 详情
      type: String,
      default: "add",
    },
    row: {
      // 判断是新建 编辑 详情
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    var checkRePwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      props: {
        label: "menu_name",
        children: "son_menu",
      },
      cityList: [],
      ruleForm: {
        admin_id: "",
        uname: "", //用户名
        // phone: "", //	手机号
        pwd: "", //密码
        rePwd: "", //重复密码
        resp_area: [], //城市
        auth_menu_json: [], // 权限
      },
      rules: {
        uname: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["change", "blur"],
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["change", "blur"],
          },
        ],
        rePwd: [{ validator: checkRePwd, trigger: "blur" }],
        resp_area: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["change", "blur"],
          },
        ],
        auth_menu_json: [
          {
            required: true,
            message: "请选择菜单权限",
            trigger: ["change", "blur"],
          },
        ],
      },
      menus: [],
    };
  },
  components: {
    Qiniu,
  },
  watch: {
    dialogVisible: {
      handler() {
        if (this.dialogVisible) {
          this.ruleForm.admin_id = this.row.admin_id || "";
          if (this.ruleForm.admin_id) {
            this.ruleForm.pwd = this.ruleForm.rePwd = "这是占位密码";
          }
          this.ruleForm.uname = this.row.uname;
          this.row.city_ids.forEach((item) => {
            this.ruleForm.resp_area.push(item * 1);
          });
          this.ruleForm.auth_menu_json = this.row.auth_menu_json;
        }
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
      },
      immediate: true,
    },
  },
  methods: {
    // 通过key设置
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys(this.ruleForm.auth_menu_json);
    },
    handleCheckChange() {
      this.ruleForm.auth_menu_json = this.$refs.tree.getCheckedKeys();
    },
    changeCate() {},
    // 获取列表
    getManagermenu() {
      this.loading = true;
      getManagermenu()
        .then((res) => {
          console.log(res);
          this.menus = res.data;
          console.log("菜单数据", this.menus);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取分类
    getManagerCity() {
      getManagerCity()
        .then((res) => {
          this.cityList = res.data;
          this.$nextTick(() => {
            this.setCheckedKeys();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    save() {
      let body = JSON.parse(JSON.stringify(this.ruleForm));
      this.typeFlag == "edit" ? (body.admin_id = this.row.admin_id) : "";
      body.resp_area = body.resp_area.toString();
      body.auth_menu_json = body.auth_menu_json.toString();
      if (this.ruleForm.pwd == "这是占位密码") {
        body.pwd = "";
      }
      saveAdmin(body)
        .then((res) => {
          if (res.code == 200) {
            this.close();
            this.$emit("getList");
          }
        })
        .catch((err) => {
          console.log(err, 222);
        });
    },
    // 关闭
    close() {
      this.ruleForm = {
        admin_id: "",
        uname: "", //用户名
        // phone: "", //	手机号
        pwd: "", //密码
        rePwd: "", //重复密码
        resp_area: [], //城市
        auth_menu_json: [], // 权限
      };
      this.$emit("close");
    },
  },
};
</script>
