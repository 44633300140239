
import request from '@/util/request'

// 【管理员管理】- 获取管理员列表
export function getAdminList(params) {
    return request({
        url: '/admin/v1/manager/list',
        method: 'get',
        params
    })
}

// 【管理员管理】- 保存管理员信息
export function saveAdmin(data) {
    return request({
        url: '/admin/v1/manager/save',
        method: 'post',
        data
    })
}

// 【管理员管理】 - 修改管理员状态
export function changeAdminStatus(data) {
    return request({
        url: '/admin/v1/manager/changeStatus',
        method: 'post',
        data
    })
}

// 【管理员管理】- 获取菜单
export function getManagermenu(params) {
    return request({
        url: '/admin/v1/manager/menu',
        method: 'get',
        params
    })
}

// 【管理员管理】- 获取所有城市
export function getManagerCity(params) {
    return request({
        url: '/admin/v1/manager/city',
        method: 'get',
        params
    })
}
