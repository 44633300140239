import request from '@/util/request'
// 获取分类列表
export function cateList(params) {
    return request({
      url: '/admin/v1/cateList',
      method: 'get',
      params
    })
}
// 添加分类
export function saveCate(data) {
    return request({
      url: '/admin/v1/saveCate',
      method: 'post',
      data
    })
}
// 删除分类
export function delCate(params) {
    return request({
      url: '/admin/v1/delCate',
      method: 'DELETE',
      params
    })
}
// 获取标签列表
export function tagList(params) {
    return request({
      url: '/admin/v1/tagList',
      method: 'get',
      params
    })
}
// 获取分类列表
export function saveTag(data) {
    return request({
      url: '/admin/v1/saveTag',
      method: 'post',
      data
    })
}
// 删除标签
export function delTag(params) {
    return request({
      url: '/admin/v1/delTag',
      method: 'DELETE',
      params
    })
}

// 获取兴趣列表
export function interest(params) {
  return request({
    url: '/admin/v1/interest',
    method: 'get',
    params
  })
}


// 添加编辑兴趣
export function addInterest(data) {
  return request({
    url: '/admin/v1/interest',
    method: 'post',
    data
  })
}


// 添加编辑兴趣
export function interestEnable(data) {
  return request({
    url: '/admin/v1/interest/enable',
    method: 'post',
    data
  })
}
